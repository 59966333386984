import { useState, useContext } from "react";
import { instance, endpoints } from "../axios/axios.config";
import { Link, useNavigate } from "react-router-dom";
import debounce from "debounce";
import Joi from "joi";
import { tlds } from "@hapi/tlds";
import DOMPurify from "dompurify";
import { validateData } from "../Utils/validationSchema";
import { AuthContext } from "../authentication/AuthContext";
import FormWrapper from "../Components/FormWrapper";
import AppButton from "../Components/AppButton";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { setBanner, setIsLoggedIn } = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!name) return;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: DOMPurify.sanitize(value),
    }));
  };

  const loginSchema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: tlds } })
      .required()
      .max(255)
      .messages({
        "string.email": "Invalid email address",
        "any.required": "Email is required",
      }),
    password: Joi.string().max(72).required().messages({
      "string.base": "Password must be a string",
      "string.max":
        "Password must be less than or equal to {{#limit}} characters long",
      "any.required": "Password is required",
    }),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const validation = validateData(formData, loginSchema);
    if (validation) {
      const newErrors = {};
      validation.details.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      return setIsLoading(false);
    }
    setErrors();
    try {
      const response = await instance.post(endpoints.login, formData);
      const data = response?.data;
      const { error } = Joi.string().required().validate(data?.jwt);
      if (error) {
        return setBanner({
          message: "Unpexpected error occured. Please try again.",
        });
      }
      localStorage.setItem("_appAT", data.jwt);
      localStorage.setItem("_iAct", true);
      setIsLoggedIn(true);
      return navigate("/");
    } catch (err) {
      if (typeof err === "object") {
        setBanner({
          message: "Invalid Request",
        });
        return;
      }
      setBanner({
        message: err,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const debouncedHandleSubmit = debounce((e) => handleSubmit(e), 300);

  return (
    <FormWrapper formTitle=" Sign in to your account" prevLink="/">
      <div className="mt-7 w-full lg:max-w-md ">
        <form
          className="space-y-6 mb-6"
          action="#"
          method="POST"
          data-testid="login-form"
          onSubmit={(e) => {
            e.preventDefault();
            debouncedHandleSubmit(e);
          }}
        >
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                maxLength={255}
                data-testid="login-email"
                autoComplete="email"
                required
                value={formData.email}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-1.5 text-black dark:text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 "
              />
            </div>
            <p className="text-error mt-1" data-testid="login-email-error">
              {errors?.email}
            </p>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="text-sm">
                <Link
                  to={"/forgot-password"}
                  className="font-semibold text-indigo-600 hover:underline"
                >
                  Forgot password?
                </Link>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                maxLength={72}
                value={formData.password}
                onChange={handleInputChange}
                data-testid="login-password"
                required
                className="block w-full rounded-md border-0 dark:text-black py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <p className="text-error mt-1">{errors?.password}</p>
          </div>

          <div>
            <AppButton text="Sign in" isLoading={isLoading} />
          </div>
        </form>

        <p className="mt-7 text-center text-sm text-gray-500">
          Dont have an account?{" "}
          <Link
            to={"/signup"}
            className="font-semibold leading-6 text-secondaryy hover:underline"
          >
            Sign up.
          </Link>
        </p>
      </div>
    </FormWrapper>
  );
};

export default Login;
