import { Link } from "react-router-dom";
import {
  CheckIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";

const NotificationBanner = ({ message, type = "error", isVerify }) => {
  let icon;
  let color;
  if (!message) {
    return;
  }
  switch (type) {
    case "error":
      icon = <ExclamationCircleIcon className="w-4 h-4 mt-1 text-white" />;
      color = "alert-error";
      break;
    case "success":
      icon = <CheckIcon className="w-4 h-4 mt-1 text-white" />;
      color = "alert-success";
      break;
    default:
      icon = <InformationCircleIcon className="w-4 h-4 mt-1 text-white" />;
      color = "alert-warning";
      break;
  }

  const alertColor = `alert ${color}  py-2 text-white text-sm `;

  return (
    <div
      className="mt-4 alert-banner mx-auto fixed bottom-5 notif-banner sm:w-11/12 sm:bottom-1"
      data-testid="notification-banner"
    >
      <div role="alert" className={alertColor}>
        {icon}
        <span className="w-fit">
          {message.error ?? message}{" "}
          {isVerify && (
            <Link to={"/verify"} className="hover:underline">
              , Verify here.
            </Link>
          )}
        </span>
      </div>
    </div>
  );
};

export default NotificationBanner;
