import Joi from "joi";
import { useState, useContext, useEffect } from "react";
import VerificationInput from "react-verification-input";
import { useNavigate } from "react-router-dom";
import debounce from "debounce";
import { AuthContext } from "../authentication/AuthContext";
import { validateData } from "../Utils/validationSchema";
import FormWrapper from "../Components/FormWrapper";
import { instance, endpoints } from "../axios/axios.config";
import AppButton from "../Components/AppButton";

const Verification = () => {
  const navigate = useNavigate();
  const [code, _setCode] = useState();
  const { setBanner } = useContext(AuthContext);
  const [isResendDisabled, setResendDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const verificationSchema = Joi.object({
    verificationId: Joi.number().max(999999).required(),
    verificationCode: Joi.string().min(6).max(6).required(),
  });

  const setCode = (value) => {
    _setCode(value);
  };

  useEffect(() => {
    const verificationId = localStorage.getItem("_ver_ID");
    if (!verificationId) {
      navigate("/signup");
    }
  }, [navigate]);

  const handleResendCode = async (e) => {
    e.preventDefault();
    setResendDisabled(true);
    const verificationId = localStorage.getItem("_ver_ID");
    const { error } = Joi.string().required().max(12).validate(verificationId);
    if (error) {
      setBanner({ message: "Unable to process this request" });
      return setIsLoading(false);
    }
    try {
      const response = await instance.post(endpoints.resendCode, {
        verificationId,
      });
      const data = response?.data;
      if (!data) {
        return setBanner({ message: "Unable to process this request" });
      }
      localStorage.setItem("_ver_ID", data.verificationId);
      const currentTimestamp = Date.now();
      localStorage.setItem("_lastResendTimestamp", currentTimestamp);
    } catch (err) {
      setResendDisabled(false);
      setBanner({ message: "Failed to resend code. Please try again" });
    }
  };

  useEffect(() => {
    const storedTimestamp = localStorage.getItem("_lastResendTimestamp");
    const validateTimestamp = Joi.number()
      .integer()
      .max(32503680000000)
      .required()
      .validate(storedTimestamp);
    if (validateTimestamp.error) {
      return setResendDisabled(false);
    }
    const timestamp = parseInt(storedTimestamp, 10);
    if (isNaN(timestamp)) {
      return setResendDisabled(false);
    }
    const timeElapsed = Date.now() - timestamp;
    if (timeElapsed > 3 * 60 * 1000) {
      return setResendDisabled(false);
    }
    setResendDisabled(true);
    const timer = setTimeout(
      () => {
        setResendDisabled(false);
      },
      5 * 60 * 1000 - timeElapsed,
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const verificationID = parseInt(localStorage.getItem("_ver_ID"), 10);
    const formData = {
      verificationId: verificationID,
      verificationCode: code,
    };
    const validation = validateData(formData, verificationSchema);
    if (validation) {
      const newErrors = {};
      validation.details.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setBanner({
        message: "Verification failed. Try resending a new code.",
      });
      setIsLoading(false);
      return;
    }
    setBanner();
    try {
      await instance.post(endpoints.verification, formData);
      localStorage.removeItem("_ver_ID");
      setBanner({
        type: "success",
        message:
          "Account verified successfully. You can now login your account",
      });
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    } catch (err) {
      if (typeof err === "object") {
        setBanner({
          message: "Invalid Request",
        });
        return;
      }
      setBanner({
        message: err,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedHandleSubmit = debounce((e) => handleSubmit(e), 300);
  return (
    <FormWrapper
      prevLink="/signup"
      formTitle="Verify your account"
      formCaption=" Please check your email for the verification code we have sent."
    >
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm flex justify-center items-center flex-col">
        <form
          className="space-y-6 mb-6 verification-form"
          action="#"
          method="POST"
          data-testid="verification-form"
          onSubmit={(e) => {
            e.preventDefault();
            debouncedHandleSubmit(e);
          }}
        >
          <VerificationInput
            value={code}
            autoFocus
            onChange={setCode}
            inputProps={{ inputMode: "numeric" }}
            validChars="0-9"
            classNames={{
              container: "",
              character:
                "block w-full rounded-lg border-0  text-black shadow-lg  ",
              characterSelected: "border-2 border-primaryy",
            }}
          />

          <div>
            <AppButton text="Submit" isLoading={isLoading} />
          </div>
        </form>

        <div className="block w-full">
          <p>
            Did not receive the code?{" "}
            <button
              className="btn btn-link p-0 hover:underline cursor-pointer text-secondaryy disabled:bg-transparent disabled:no-underline"
              onClick={handleResendCode}
              disabled={isResendDisabled}
              data-testid="verification-resend"
            >
              {isResendDisabled
                ? "Code sent. Resend after 3 mins"
                : "Resend verification code"}
            </button>
          </p>
        </div>
      </div>
    </FormWrapper>
  );
};

export default Verification;
