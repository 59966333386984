import React, { useState, useContext } from "react";
import Joi from "joi";
import { useLocation } from "react-router-dom";
import debounce from "debounce";
import { tlds } from "@hapi/tlds";
import { instance, endpoints } from "../axios/axios.config";
import AppButton from "../Components/AppButton";
import FormWrapper from "../Components/FormWrapper";
import { AuthContext } from "../authentication/AuthContext";
import DOMPurify from "dompurify";
import { validateData } from "../Utils/validationSchema";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Unsubscribed = () => {
  const query = useQuery();
  const urlEmail = query.get("email");
  const [formData, setFormData] = useState({
    email: urlEmail || "",
    isSubscribe: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { setBanner } = useContext(AuthContext);
  const [errors, setErrors] = useState({});

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: tlds } })
      .max(255)
      .required()
      .messages({
        "string.email": "Must be a valid email address",
      }),
    isSubscribe: Joi.number().valid(0, 1, 2).required().messages({
      "number.base": "Must not be empty",
      "any.required": "Must not be empty",
      "number.invalid": "Must be a valid option",
    }),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!name) return;
    if (name === "isSubscribe") {
      setFormData({
        ...formData,
        [name]: Number(DOMPurify.sanitize(value)),
      });
      return;
    }
    setFormData({
      ...formData,
      [name]: DOMPurify.sanitize(value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const validation = validateData(formData, schema);
    if (validation) {
      const newErrors = {};
      validation.details.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }
    setErrors();
    try {
      await instance.post(endpoints.unsubscribe, formData);
      setBanner({
        type: "success",
        message: "Successfuly unsubscribed to our newsletters",
      });
    } catch (err) {
      setBanner({
        message: err,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedHandleSubmit = debounce((e) => handleSubmit(e), 300);

  return (
    <FormWrapper
      formTitle="Unsubscribe"
      formCaption="Please confirm that you want to stop getting emails from us."
      prevLink="/"
    >
      <div className="mt-4 w-full lg:max-w-md">
        <form
          className="space-y-6"
          action="#"
          method="POST"
          onSubmit={(e) => {
            e.preventDefault();
            debouncedHandleSubmit(e);
          }}
        >
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Your email address:
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                required
                disabled={urlEmail ? true : false}
                value={formData.email}
                onChange={handleInputChange}
                autoComplete="false"
                className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
            <p className="text-red mt-1">{errors?.email}</p>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                How often do you like to hear from us?
              </label>
              <div className="mt-2 flex flex-col gap-1 pl-1">
                <div className="w-fit">
                  <label className="label  cursor-pointer flex gap-3 justify-start">
                    <input
                      type="radio"
                      name="isSubscribe"
                      value={1}
                      data-testid="signup-radio-principal-adviser"
                      className="radio checked:bg-primaryy radio-sm "
                      checked={formData.isSubscribe === 1}
                      onChange={handleInputChange}
                    />
                    <span className="label-text">Monthly</span>
                  </label>
                </div>
                <div className="w-fit">
                  <label className="label cursor-pointer flex gap-3 justify-start">
                    <input
                      type="radio"
                      name="isSubscribe"
                      value={2}
                      data-testid="signup-radio-principal-adviser1"
                      className="radio checked:bg-primaryy radio-sm"
                      checked={formData.isSubscribe === 2}
                      onChange={handleInputChange}
                    />
                    <span className="label-text">Bimonthly </span>
                  </label>
                </div>
                <div className="w-fit">
                  <label className="label cursor-pointer flex gap-3 justify-start">
                    <input
                      type="radio"
                      name="isSubscribe"
                      value={0}
                      data-testid="signup-radio-principal-adviser1"
                      className="radio checked:bg-primaryy radio-sm"
                      checked={formData.isSubscribe === 0}
                      onChange={handleInputChange}
                    />
                    <span className="label-text">Unsubscribe</span>
                  </label>
                </div>
              </div>
            </div>
            <p className="text-red mt-1">{errors?.isSubscribe}</p>
          </div>

          <div>
            <AppButton text="Submit" isLoading={isLoading} />
          </div>
        </form>
      </div>
    </FormWrapper>
  );
};

export default Unsubscribed;
