import {
  DocumentTextIcon,
  FilmIcon,
  DocumentIcon,
} from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { instance, endpoints } from "../axios/axios.config";
import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { AuthContext } from "../authentication/AuthContext";
import Vimeo from "@vimeo/player";
import Joi from "joi";

const PopupContentBody = ({ content }) => {
  const [player, setPlayer] = useState(null);
  const [downloadLink, setDownloadLink] = useState("");
  const [downloadFilename, setDownloadFileName] = useState();
  const [isDownload, setIsDownloadable] = useState(false);
  const [renderedIcon, setRenderIcon] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { isLoggedin } = useContext(AuthContext);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const initialMount = useRef(true);

  useEffect(() => {
    if (content?.type === "video") {
      setRenderIcon(<FilmIcon className="h-5 mt-1" />);
    } else if (content?.type === "article") {
      setRenderIcon(<DocumentTextIcon className="h-5 mt-1" />);
    } else {
      setRenderIcon(<DocumentIcon className="h-5 mt-1" />);
    }
  }, [content]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getSignedUrl = useCallback(async () => {
    try {
      if (!isLoggedin || !content) return;
      const { fileName } = content;
      const headers = { "Content-Type": "application/json" };
      const res = await instance.post(
        endpoints.getsignedurl,
        { fileName },
        { headers },
      );
      if (!res?.data) return;
      const { error } = Joi.string()
        .uri()
        .trim()
        .required()
        .validate(res.data.signedUrl);
      if (error) {
        return;
      }

      const resBlob = await fetch(res.data.signedUrl).then((response) =>
        response.blob(),
      );
      const url = window.URL.createObjectURL(resBlob);
      setDownloadLink(url);
      setDownloadFileName(content.fileName);
      setIsDownloadable(true);
    } catch (err) {
      return;
    } finally {
      setIsLoading(false);
    }
  }, [content, isLoggedin]);

  const initializePlayer = useCallback(async () => {
    if (!content.vimeoID) return;

    const vimeoPlayer = new Vimeo("vimplayer", {
      id: content.vimeoID,
      title: false,
      autoplay: false,
      controls: (isLoggedin || content.isFeatured) ?? false,
      width: screenWidth > 640 ? 640 : screenWidth - 60,
      height: screenWidth > 640 ? 360 : (screenWidth * 360) / 640,
    });
    setPlayer(vimeoPlayer);
    setIsLoading(false);
  }, [content, isLoggedin, screenWidth]);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
      setIsLoading(true);
      if (content.type === "video") {
        initializePlayer();
      }
      if (content.fileName && !content.vimeoID) {
        getSignedUrl();
      }
    }
    return () => {
      if (player) {
        player.destroy();
      }
      if (downloadLink) {
        URL.revokeObjectURL(downloadLink);
      }
    };
  }, [content, initializePlayer, getSignedUrl, player, downloadLink]);

  const actionBtn = isLoggedin
    ? downloadLink &&
      isDownload && (
        <a
          id="downloadBtn"
          className="btn border-0 text-white bg-secondaryy hover:bg-grey"
          href={downloadLink}
          download={downloadFilename}
        >
          Download
        </a>
      )
    : !content.isFeatured && (
        <div>
          <p className="py-4">
            To have access to this content, please{" "}
            <Link to={"/login"} className="link">
              login
            </Link>{" "}
            your account or{" "}
            <Link to={"/signup"} className="link">
              sign up here
            </Link>
            .
          </p>
        </div>
      );

  return (
    <>
      <div className="card content-card shadow-none w-full bg-transparent px-0">
        {content.type === "video" && content.vimeoID ? (
          <div id="vimplayer"></div>
        ) : (
          <figure className="w-full pt-10 bg-transparent">
            <img
              src={content.thumbnail}
              alt="Content Thumbnail"
              className="rounded-xl w-full"
            />
          </figure>
        )}
        <div className="card-body items-center text-center px-0">
          <h2 className="card-title items-start">
            {renderedIcon}
            {content.title}
          </h2>
          <div className="mb-4">
            <span className="capitalize font-bold ">
              {content.type} | {content.date}
            </span>
          </div>
          <p>{content.description}</p>
          <div className="card-actions mt-4">
            {isLoading ? (
              <span className="loading loading-spinner loading-sm"></span>
            ) : (
              actionBtn
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupContentBody;
