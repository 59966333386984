import { Outlet, Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef, useContext, useCallback } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { instance, endpoints } from "../axios/axios.config";
import { AuthContext } from "../authentication/AuthContext";
import { UserIcon, HomeIcon } from "@heroicons/react/24/solid";

export default function Root() {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { logout, isLoggedin, saveUserInfo, getUserInfo } =
    useContext(AuthContext);
  const initialMount = useRef(true);

  const _logout = () => {
    logout();
    navigate("/login");
  };

  const avatarMenuHtml = (
    <>
      <div
        tabIndex={0}
        role="button"
        className="btn btn-ghost h-fit btn-circle w-10 hover:bg-transparent "
      >
        <div className="avatar  placeholder">
          <div className="text-neutral-content w-8 bg-white rounded-full ring ring-transparent  ring-offset-1">
            <UserIcon className="w-5 h-5" />
          </div>
        </div>
      </div>
      <ul
        tabIndex={0}
        className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52"
      >
        <li>
          <Link to={"/settings"}>Settings</Link>
        </li>
        <li>
          <Link to={"#"} onClick={_logout}>
            Logout
          </Link>
        </li>
      </ul>
    </>
  );

  const getUser = useCallback(async () => {
    const appUserInfo = getUserInfo();
    if (appUserInfo) {
      return;
    }
    setIsLoading(true);
    try {
      const headers = { "Content-Type": "application/json" };
      const response = await instance.post(
        endpoints.getUserData,
        {},
        { headers },
      );
      const data = response?.data;
      if (Object.keys(data.user).length === 0)
        throw new Error("Failed to fetch user data");
      saveUserInfo(data?.user);
    } catch (error) {
      return;
    } finally {
      setIsLoading(false);
    }
  }, [getUserInfo, saveUserInfo]);

  useEffect(() => {
    if (
      initialMount.current &&
      isLoggedin &&
      window.location.pathname !== "/settings"
    ) {
      initialMount.current = false;
      getUser();
    }
  }, [getUser, isLoggedin]);

  return (
    <>
      <header className="bg-white shadow-md fixed w-screen top-0 z-50">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <Link to={"/"} className="-m-1.5 p-1.5">
              <span className="sr-only">CoreData Exchange Library</span>
              <img
                className="h-10 w-auto lg:h-14"
                src="https://assets.coredataresearch.com/coredataexchange.com/images/cd_exchange_logo.png"
                alt="CoreData Exchange Library"
              />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              aria-label="Menu button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-4 items-center">
            {isLoggedin && !isLoading ? (
              <Link to={"/"}>
                <div className="avatar  placeholder ">
                  <div className="text-neutral-content w-8 bg-white rounded-full ring ring-transparent  ring-offset-1">
                    <HomeIcon className="w-5 h-5" />
                  </div>
                </div>
              </Link>
            ) : (
              ""
            )}

            {!isLoggedin && !isLoading ? (
              <>
                <Link to={"/login"} className="font-semibold mr-4">
                  Login
                </Link>
                <Link
                  to={"/signup"}
                  className="bg-secondaryy text-white rounded-full py-2 px-3 leading-normal font-semibold"
                >
                  Signup
                </Link>
              </>
            ) : (
              ""
            )}
            <div className="dropdown dropdown-end">
              {isLoggedin && !isLoading ? avatarMenuHtml : ""}
            </div>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-end">
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-10 flow-root">
              {isLoggedin ? (
                <div className="-my-6">
                  <div className="space-y-2 py-3">
                    <Link
                      to={"/settings"}
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Settings
                    </Link>
                  </div>
                  <div className="py-3">
                    <Link
                      to={"/login"}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      onClick={_logout}
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="-my-6">
                  <div className="space-y-2 py-3">
                    <Link
                      to={"/login"}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Login
                    </Link>
                  </div>
                  <div className="py-3">
                    <Link
                      to={"/signup"}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Signup
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <div id="detail">
        <Outlet />
      </div>
      <footer className="footer footer-center p-4 text-white px-10 py-5">
        <div className="flex items-center justify-around w-full lg:flex-row md:flex-row sm:flex-col flex-col">
          <div className="footer-left flex flex-col items-start gap-1">
            <span>Suite 412, Lime St, Sydney NSW 2000</span>
            <span>+61 400 54 96 54</span>
            <span>www.coredataexchange.com.au</span>
            <aside className="text-xs"></aside>
          </div>
          <div className="footer-right">
            <Link to={"/"}>
              <img
                className="mx-auto h-20 w-auto"
                src="https://assets.coredataresearch.com/coredataexchange.com/images/cd_exchange_logo.png"
                alt="CoreData Exchange Library"
              />
            </Link>
            <aside className="text-xs mt-5">
              <p>
                Copyright © 2024 - All rights reserved by CoreData Exchange
              </p>
            </aside>
          </div>
        </div>
      </footer>
    </>
  );
}
