import axios from "axios";
import Joi from "joi";
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;

export const endpoints = {
  register: "register",
  verification: "validatecode",
  resendCode: "resendCode",
  createPassword: "createpassword",
  updatePassword: "updatepassword",
  updateUserInfo: "updateuser",
  login: "login",
  unsubscribe: "unsubscribe",
  getsignedurl: "getsignedurl",
  getUserData: "getuser",
  forgotPassword: "forgotpassword",
  validatePasswordLink: "validatepasswordlink",
};
export const instance = axios.create({
  baseURL: API_ENDPOINT,
});

instance.defaults.timeout = 5000;
instance.defaults.headers.common["Content-Type"] = "application/json";

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("_appAT");
    if (token) {
      const { error } = Joi.string().required().max(2000).validate(token);
      if (error) {
        return;
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response &&
      error.response?.status === 401 &&
      (error.response?.config?.url === endpoints.updatePassword ||
        error.response?.config?.url === endpoints.updateUserInfo ||
        error.response?.config?.url === endpoints.getsignedurl)
    ) {
      localStorage.removeItem("_userIsLoggedIn");
      localStorage.removeItem("_appAT");
      localStorage.removeItem("_ver_ID");
      window.location = "/logout";
    }
    return Promise.reject(error.response.data);
  },
);
