import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./Pages/ErrorPage.jsx";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import ContentCard from "./Components/ContentCard.jsx";
import AuthProvider from "./authentication/AuthContext.jsx";
import Settings from "./Pages/Settings.jsx";
import Verification from "./Pages/Verification.jsx";
import ForgotPassword from "./Pages/ForgotPassword.jsx";
import Logout from "./Components/Logout.jsx";
import CreatePassword from "./Pages/CreatePassword";
import Unsubscribe from "./Pages/Unsubscribe.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <Root />
      </div>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <ContentCard />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
    ],
  },
  {
    path: "/create-password",
    element: (
      <div>
        <CreatePassword />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/verify",
    element: (
      <div>
        <Verification />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: (
      <div>
        <Login />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/logout",
    element: (
      <div>
        <Logout />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/signup",
    element: (
      <div>
        <SignUp />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgot-password",
    element: (
      <div>
        <ForgotPassword />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/unsubscribe",
    element: (
      <div>
        <Unsubscribe />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
]);

const App = () => {
  return (
    <div>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </div>
  );
};

export default App;
