import { useEffect, useState, useRef, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "debounce";
import DOMPurify from "dompurify";
import Joi from "joi";
import { tlds } from "@hapi/tlds";
import { validateData } from "../Utils/validationSchema";
import { instance, endpoints } from "../axios/axios.config";
import { AuthContext } from "../authentication/AuthContext";
import FormWrapper from "../Components/FormWrapper";
import AppButton from "../Components/AppButton";
const Settings = () => {
  const navigate = useNavigate();
  const { saveUserInfo, setBanner, getUserInfo, isLoggedin } =
    useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [isUpdateOtherInfo, setIsUpdateOtherInfo] = useState(false);
  const [parsedUserInfo, setParseUserInfo] = useState();

  const [formData, setFormData] = useState({
    isSubscribe: 0,
    phone: "",
    principalOrAdviser: "",
    advisersNo: "",
    isSelfLicensed: 0,
  });
  const [passwordFormData, setPasswordFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const australianPhoneNumberRegex = /^(1[4698]|2|3|4|5[50]|7|8)\d{8}$/;
  const allowedLettersHypens =
    /^[a-zA-ZñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙâêîôûÂÊÎÔÛäëïöüÄËÏÖÜãõÃÕçÇ' -]+$/;
  const passwordSchema = Joi.object({
    oldPassword: Joi.string().min(8).max(72).required().messages({
      "string.min": "Must be at least {{#limit}} characters long",
    }),
    newPassword: Joi.string().min(8).max(72).required().messages({
      "string.min": "Must be at least {{#limit}} characters long",
    }),
    confirmPassword: Joi.string()
      .valid(Joi.ref("newPassword"))
      .min(8)
      .max(72)
      .required()
      .messages({
        "any.only": "Must be matching passwords",
        "string.min": " Must be at least {{#limit}} characters long",
      }),
  });
  const updateUserSchema = Joi.object({
    phone: Joi.string().pattern(australianPhoneNumberRegex).max(9).messages({
      "string.pattern.base": "Invalid australian phone number",
    }),
    principalOrAdviser: Joi.string()
      .valid("practice principal", "adviser")
      .required(),
    advisersNo: Joi.string()
      .valid("0", "1", "2", "3", "4", "5", "more than 5")
      .required(),
    isSelfLicensed: Joi.number().valid(0, 1).required(),
    isSubscribe: Joi.number().valid(0, 1, 2).required().messages({
      "number.base": "Must not be empty",
      "any.required": "Must not be empty",
      "number.invalid": "Must be a valid option",
    }),
  }).messages({
    "any.any": "Invalid input",
    "string.empty": "Input field required",
  });

  const userLoggedInSchema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: tlds } })
      .max(255)
      .required()
      .messages({
        "string.email": "must be a valid email address",
      }),
    firstName: Joi.string()
      .pattern(allowedLettersHypens)
      .min(2)
      .max(50)
      .required(),
    lastName: Joi.string()
      .pattern(allowedLettersHypens)
      .min(2)
      .max(50)
      .required(),
    phone: Joi.string()
      .pattern(/^\+61(1[4698]|2|3|4|5[50]|7|8)\d{8}$/)
      .max(12)
      .messages({
        "string.pattern.base": "Invalid australian phone number",
      }),
    principalOrAdviser: Joi.string()
      .valid("practice principal", "adviser")
      .required(),
    advisersNo: Joi.string()
      .valid("0", "1", "2", "3", "4", "5", "more than 5")
      .required(),
    isSelfLicensed: Joi.number().valid(0, 1).required(),
    isSubscribe: Joi.number().valid(0, 1, 2).required(),
  }).messages({
    "any.any": "Invalid input",
    "string.empty": "Input field required",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!name) return;
    if (name === "phone") {
      const numericValue = value.replace(/\D/g, "").replace(/^0+/, "");
      return setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: DOMPurify.sanitize(numericValue),
      }));
    }
    if (name === "isSubscribe") {
      setFormData({
        ...formData,
        [name]: Number(DOMPurify.sanitize(value)),
      });
      return;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: DOMPurify.sanitize(value),
    }));
  };
  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    if (!name) return;
    setPasswordFormData((prevPasswordFormData) => ({
      ...prevPasswordFormData,
      [name]: DOMPurify.sanitize(value),
    }));
  };
  const sanitize = (data) => {
    if (data) {
      return DOMPurify.sanitize(data).toLowerCase();
    }
    return;
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (!name) return;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked ? 1 : 0,
    }));
  };
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingPassword(true);
    const validation = validateData(passwordFormData, passwordSchema);
    if (validation) {
      const newErrors = {};
      validation.details.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      return setIsLoadingPassword(false);
    }
    setErrors();
    try {
      await instance.put(endpoints.updatePassword, passwordFormData);
      setPasswordFormData({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });

      navigate("/settings");
      setBanner({
        type: "success",
        message: "Password updated successfully",
      });
      return;
    } catch (err) {
      setBanner({
        message: err,
      });
    } finally {
      setIsLoadingPassword(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const validation = validateData(formData, updateUserSchema);
    if (validation) {
      const newErrors = {};
      validation.details.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      return setIsLoading(false);
    }
    setErrors();
    try {
      const response = await instance.put(endpoints.updateUserInfo, formData);
      const data = response?.data;
      const validationUser = validateData(data?.user, userLoggedInSchema);
      if (validationUser) {
        const newErrors = {};
        validationUser.details.forEach((err) => {
          newErrors[err.path[0]] = err.message;
        });
        setBanner({
          message: "Error updating your account. Please try again",
        });
        setIsLoading(false);
        return;
      }
      saveUserInfo(data.user);
      setParseUserInfo(data.user);
      setBanner({
        type: "success",
        message: "Profile updated successfully",
      });
    } catch (err) {
      setBanner({
        message: err,
      });
    } finally {
      setIsLoading(false);
      setIsUpdateOtherInfo(false);
    }
  };
  const debouncedHandleSubmit = debounce((e) => handleSubmit(e), 300);
  const debouncedHandleSubmitPassword = debounce(
    (e) => handlePasswordSubmit(e),
    300,
  );
  const cancelUpdateInfo = () => {
    setErrors({});
    setFormData({
      isSubscribe: parsedUserInfo?.isSubscribe,
      phone: parsedUserInfo?.phone.replace("+61", ""),
      principalOrAdviser: parsedUserInfo?.principalOrAdviser,
      advisersNo: parsedUserInfo?.advisersNo,
      isSelfLicensed: parsedUserInfo?.isSelfLicensed,
    });
    setIsUpdateOtherInfo(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialMount = useRef(true);
  const fetchUser = useCallback(async () => {
    try {
      const headers = { "Content-Type": "application/json" };
      const response = await instance.post(
        endpoints.getUserData,
        {},
        { headers },
      );
      const data = response?.data;
      if (Object.keys(data.user).length === 0) {
        setBanner({ message: "Failed to fetch user data" });
      }

      saveUserInfo(data.user);
      setParseUserInfo(data.user);
      setFormData({
        isSubscribe: data.user.isSubscribe,
        phone: data.user.phone.replace("+61", ""),
        principalOrAdviser: data.user.principalOrAdviser,
        advisersNo: data.user.advisersNo,
        isSelfLicensed: data.user.isSelfLicensed,
      });
    } catch (error) {
      navigate("/logout");
    }
  }, [saveUserInfo, navigate, setBanner]);

  useEffect(() => {
    if (!isLoggedin) {
      return navigate("/logout");
    }
    if (initialMount.current) {
      initialMount.current = false;
      const appUserInfo = getUserInfo();
      if (appUserInfo) {
        setParseUserInfo(appUserInfo);
        setFormData({
          isSubscribe: appUserInfo.isSubscribe,
          phone: appUserInfo.phone.replace("+61", ""),
          principalOrAdviser: appUserInfo.principalOrAdviser,
          advisersNo: appUserInfo.advisersNo,
          isSelfLicensed: appUserInfo.isSelfLicensed,
        });
      } else {
        fetchUser();
      }
    }
  }, [getUserInfo, fetchUser, isLoggedin, navigate]);

  return (
    <FormWrapper isInnerPage={true}>
      <h2 className="text-2xl mb-5 font-bold w-full ">Account</h2>
      <div className="flex flex-col items-start w-full ">
        <div className="flex gap-1">
          Email:{" "}
          <p className="font-semibold text-black">
            {sanitize(parsedUserInfo?.email)}
          </p>
        </div>
        <div className="flex gap-1">
          Name:{" "}
          <p className="font-semibold text-black">
            {sanitize(parsedUserInfo?.firstName)}{" "}
            {sanitize(parsedUserInfo?.lastName)}
          </p>
        </div>
      </div>

      <>
        <div className="divider my-2"></div>
        <h4 className="text-2xl mb-5 font-bold w-full">Change Password</h4>
        <div className="w-full">
          <form
            className="space-y-2 lg:w-1/2 sm:w-full"
            action="#"
            method="POST"
            onSubmit={(e) => {
              e.preventDefault();
              debouncedHandleSubmitPassword(e);
            }}
          >
            <div>
              <label
                htmlFor="oldPassword"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Old Password
              </label>
              <div className="mt-2">
                <input
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  required
                  value={passwordFormData?.oldPassword}
                  onChange={handlePasswordInputChange}
                  autoComplete="false"
                  className={`block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                    errors?.oldPassword && "ring-red"
                  }`}
                />
              </div>
              <p className="text-red mt-1">{errors?.oldPassword}</p>
            </div>

            <div>
              <label
                htmlFor="newPassword"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                New Password
              </label>
              <div className="mt-2">
                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  required
                  value={passwordFormData?.newPassword}
                  onChange={handlePasswordInputChange}
                  autoComplete="false"
                  className={`block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                    errors?.newPassword && "ring-red"
                  }`}
                />
              </div>
              <p className="text-red mt-1">{errors?.newPassword}</p>
            </div>

            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Confirm Password
              </label>
              <div className="mt-2">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  required
                  value={passwordFormData?.confirmPassword}
                  onChange={handlePasswordInputChange}
                  autoComplete="false"
                  className={`block ${
                    errors?.confirmPassword && "ring-red"
                  } w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                />
              </div>
              <p className="text-red mt-1">{errors?.confirmPassword}</p>
            </div>

            <div className="pt-4">
              <AppButton text="Update password" isLoading={isLoadingPassword} />
            </div>
          </form>
        </div>
      </>

      <div className="divider my-2"></div>
      <h4 className="text-2xl mb-5 font-bold w-full">Additional Info</h4>
      <div className="w-full">
        <form
          className="space-y-2 w-full"
          action="#"
          method="POST"
          onSubmit={(e) => {
            e.preventDefault();
            debouncedHandleSubmit(e);
          }}
        >
          <div className="flex items-center md:flex-row lg:flex-row sm:flex-col flex-col ">
            <label
              htmlFor="last_name"
              className=" text-sm font-medium leading-6 text-gray-900 w-full lg:w-1/2 md:w-full sm:w-full flex lg:flex-col md:flex-col sm:flex-row flex-row sm:gap-2 gap-1"
            >
              Mobile Number:{" "}
              <p className="font-semibold text-sm text-black">
                {sanitize(parsedUserInfo?.phone) ?? (
                  <span className="loading loading-spinner loading-sm"></span>
                )}
              </p>
            </label>

            {isUpdateOtherInfo && (
              <label className="input input-bordered  input-sm px-0 flex items-center  gap-2 w-full lg:w-1/2 md:w-full sm:w-full border-0 justify-start sm:justify-start md:justify-end lg:justify-end">
                <label
                  className={` w-1/2 input-bordered ${
                    errors?.phone && "ring-red"
                  } input-sm flex items-center gap-2  rounded-md shadow-sm ring-1 ring-inset border-0 focus:ring-inset focus:ring-2`}
                >
                  +61
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    maxLength={9}
                    onChange={handleInputChange}
                    className="block w-full  placeholder:text-gray-400 ring-0 focus:ring-0 border-0 outline-0 sm:text-sm sm:leading-6 text-sm"
                  />
                </label>
              </label>
            )}
          </div>

          <div>
            <div className="mt-2 flex items-center md:flex-row lg:flex-row sm:flex-col flex-col">
              <label className="input input-bordered pl-0 border-0 w-full lg:w-1/2 md:w-full sm:w-full">
                Practice Principal/Adviser:{" "}
                <p className="font-semibold text-sm text-black">
                  {sanitize(parsedUserInfo?.principalOrAdviser) ?? (
                    <span className="loading loading-spinner loading-sm"></span>
                  )}
                </p>
              </label>
              {isUpdateOtherInfo && (
                <div className="flex gap-6 w-full lg:w-1/2 md:w-full sm:w-full items-center justify-start sm:justify-start md:justify-end lg:justify-end">
                  <div>
                    <label className="label px-0 cursor-pointer flex gap-2 ">
                      <input
                        type="radio"
                        name="principalOrAdviser"
                        value="practice principal"
                        className="radio checked:bg-primaryy radio-sm "
                        checked={
                          formData.principalOrAdviser === "practice principal"
                        }
                        onChange={handleInputChange}
                      />
                      <span className="label-text">Practice Principal</span>
                    </label>
                  </div>
                  <div>
                    <label className="label cursor-pointer flex gap-2">
                      <input
                        type="radio"
                        name="principalOrAdviser"
                        value="adviser"
                        className="radio checked:bg-primaryy radio-sm"
                        checked={formData.principalOrAdviser === "adviser"}
                        onChange={handleInputChange}
                      />
                      <span className="label-text">Adviser</span>
                    </label>
                  </div>
                </div>
              )}
            </div>
            <p className="text-error mt-1 text-sm">
              {errors?.principalOrAdviser}
            </p>
          </div>

          <div className="flex items-center md:flex-row lg:flex-row sm:flex-col flex-col">
            <label
              htmlFor="company"
              className="block text-sm font-medium leading-6 text-gray-900 w-full lg:w-1/2 md:w-full sm:w-full"
            >
              No Advisers in your Practice:{" "}
              <p className="font-semibold text-sm text-black">
                {" "}
                {sanitize(parsedUserInfo?.advisersNo) ?? (
                  <span className="loading loading-spinner loading-sm"></span>
                )}
              </p>
            </label>
            {isUpdateOtherInfo && (
              <div className="mt-2 w-full lg:w-1/2 md:w-full flex justify-start sm:justify-start md:justify-end lg:justify-end sm:w-full">
                <select
                  name="advisersNo"
                  id="advisersNo"
                  value={formData.advisersNo}
                  onChange={handleInputChange}
                  className="select select-bordered select-sm block w-1/2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm leading-4"
                >
                  <option value="0">N/A</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="more than 5">more than 5</option>
                </select>
              </div>
            )}
            <p className="text-error mt-1 text-sm">{errors?.advisersNo}</p>
          </div>

          <div className="flex items-start md:flex-row lg:flex-row sm:flex-col flex-col">
            <label
              htmlFor="company"
              className="block text-sm font-medium leading-6 text-gray-900 w-full "
            >
              Self Licensed:{" "}
              <p className="font-semibold text-sm text-black">
                {" "}
                {parsedUserInfo?.isSelfLicensed === 1 ? "Yes" : "No"}
              </p>
            </label>
            <div className="form-control items-start sm:items-start md:items-end  lg:items-end lg:w-1/2 md:w-full sm:w-full ">
              {isUpdateOtherInfo && (
                <label className="cursor-pointer label  ">
                  <input
                    id="isSelfLicensed"
                    type="checkbox"
                    name="isSelfLicensed"
                    checked={formData?.isSelfLicensed === 1}
                    className="checkbox checkbox-info checkbox-sm"
                    value={formData?.isSelfLicensed}
                    onChange={handleCheckboxChange}
                  />
                  <span className="label-text ml-3">I am self licensed:</span>
                </label>
              )}
            </div>
            <p className="text-error mt-1 text-sm">{errors?.isSelfLicensed}</p>
          </div>
          <div>
            <div className="form-control items-start">
              <div className="items-center w-full justify-between bg-grey p-2 text-white glass border-0 rounded-md m-1 text-sm flex">
                {parsedUserInfo?.isSubscribe !== 0
                  ? "You are currently subscribed to our exclusive offers"
                  : "Not yet subscribed to CoreData Exchange exclusive offers."}
              </div>
              {isUpdateOtherInfo && (
                <div className="mt-2 flex flex-col gap-0 pl-1">
                  <div className="w-fit">
                    <label className="label  cursor-pointer flex gap-3 justify-start">
                      <input
                        type="radio"
                        name="isSubscribe"
                        value={1}
                        data-testid="signup-radio-principal-adviser"
                        className="radio checked:bg-primaryy radio-sm "
                        checked={formData.isSubscribe === 1}
                        onChange={handleInputChange}
                      />
                      <span className="label-text">Monthly</span>
                    </label>
                  </div>
                  <div className="w-fit">
                    <label className="label cursor-pointer flex gap-3 justify-start">
                      <input
                        type="radio"
                        name="isSubscribe"
                        value={2}
                        data-testid="signup-radio-principal-adviser1"
                        className="radio checked:bg-primaryy radio-sm"
                        checked={formData.isSubscribe === 2}
                        onChange={handleInputChange}
                      />
                      <span className="label-text">Bimonthly </span>
                    </label>
                  </div>
                  <div className="w-fit">
                    <label className="label cursor-pointer flex gap-3 justify-start">
                      <input
                        type="radio"
                        name="isSubscribe"
                        value={0}
                        data-testid="signup-radio-principal-adviser1"
                        className="radio checked:bg-primaryy radio-sm"
                        checked={formData.isSubscribe === 0}
                        onChange={handleInputChange}
                      />
                      <span className="label-text">
                        Dont want to receive emails
                      </span>
                    </label>
                  </div>
                </div>
              )}
            </div>
            <p className="text-red-700 mt-1">{errors?.isSubscribe}</p>
          </div>

          <div className="">
            {!isUpdateOtherInfo && (
              <button
                type="button"
                onClick={() => {
                  setIsUpdateOtherInfo(true);
                }}
                className="flex w-1/2 justify-center rounded-md bg-primaryy px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primaryy focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:underline"
              >
                {isLoading ? (
                  <span className="loading loading-spinner loading-sm"></span>
                ) : (
                  "Update info"
                )}
              </button>
            )}
            {isUpdateOtherInfo && (
              <div className="flex gap-2 mt-6 ">
                <button
                  type="button"
                  onClick={() => {
                    cancelUpdateInfo();
                  }}
                  className=" flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-grey shadow-md hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:underline"
                >
                  Cancel
                </button>
                <AppButton text="Save" isLoading={isLoading} />
              </div>
            )}
          </div>
        </form>
      </div>
    </FormWrapper>
  );
};

export default Settings;
