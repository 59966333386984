const FeaturedContent = ({ openFeatured }) => {
  return (
    <div>
      <div className="hero featured-content text-white ">
        <div className="hero-content flex-col gap-6 lg:flex-row">
          <div className="relative hover:scale-105 transition-all lg:w-1/2 md:w-1/2 sm:w-full">
            <img
              src="https://assets.coredataresearch.com/coredataexchange.com.au/images/library/cdx-video-1.png"
              className="m rounded-lg shadow-2xl  :w-full "
              alt="CoreData Exchange"
            />
          </div>
          <div className="lg:w-1/2 md:w-1/2 sm:w-full">
            <h1 className="text-4xl font-bold">
              Introducing CoreData Exchange
            </h1>
            <h2 className="hidden">CoreData Exchange Library</h2>
            <div className="badge bg-secondaryy border-none text-white ">
              Featured
            </div>
            <p className="py-5">
              CoreData Exchange is all about bringing people together to
              collaborate and leverage data driven insights, with a focus on a
              peer to peer exchange of knowledge and experience...
            </p>
            <button
              className="btn  btn-outline btn-sm border-white text-black hover:bg-secondaryy hover:border-secondaryy hover:text-white py-0 px-4 rounded-full  bg-white  "
              onClick={openFeatured}
            >
              View
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedContent;
