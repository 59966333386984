const PageLoader = () => {
  return (
    <div className="w-full text-center">
      Verifying link...
      <br />
      <span className="loading loading-spinner loading-sm"></span>
    </div>
  );
};

export default PageLoader;
