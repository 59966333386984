import { createContext, useState } from "react";
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedin, setIsLoggedIn] = useState(
    localStorage.getItem("_iAct") ?? false,
  );
  const [userInfo, _setUserInfo] = useState();
  const [banner, setBanner] = useState();

  const logout = () => {
    setIsLoggedIn(false);
    _setUserInfo();
    localStorage.clear();
  };

  const getUserInfo = () => {
    if (!userInfo || Object.keys(userInfo).length === 0) return null;
    return JSON.parse(userInfo);
  };

  const saveUserInfo = (data) => {
    _setUserInfo(JSON.stringify(data));
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedin,
        banner,
        setIsLoggedIn,
        saveUserInfo,
        logout,
        setBanner,
        getUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
