import { useState, useContext } from "react";
import Joi from "joi";
import debounce from "debounce";
import { tlds } from "@hapi/tlds";
import { instance, endpoints } from "../axios/axios.config";
import AppButton from "../Components/AppButton";
import FormWrapper from "../Components/FormWrapper";
import { AuthContext } from "../authentication/AuthContext";
import DOMPurify from "dompurify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setBanner } = useContext(AuthContext);
  const [error, setError] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const validation = Joi.string()
      .email({ tlds: { allow: tlds } })
      .max(255)
      .required()
      .validate(email);
    if (validation.error) {
      setError(validation.error.message);
      return setIsLoading(false);
    }
    setError();
    try {
      await instance.post(endpoints.forgotPassword, {
        email: email,
      });
      setBanner({
        type: "success",
        message: "Password reset link sent to email. You may close this tab.",
      });
    } catch (err) {
      setBanner({
        message: err,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedHandleSubmit = debounce((e) => handleSubmit(e), 300);

  return (
    <FormWrapper
      formTitle="Forgot Password"
      formCaption="Provide your email address so we can send a password reset link."
      prevLink="/login"
    >
      <div className="mt-4 w-full lg:max-w-md">
        <form
          className="space-y-6"
          action="#"
          method="POST"
          onSubmit={(e) => {
            e.preventDefault();
            debouncedHandleSubmit(e);
          }}
        >
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
                autoComplete="false"
                className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>
            <p className="text-red mt-1">{error?.email}</p>
          </div>

          <div>
            <AppButton text="Submit" isLoading={isLoading} />
          </div>
        </form>
      </div>
    </FormWrapper>
  );
};

export default ForgotPassword;
