import React, { useEffect, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Joi from "joi";
import debounce from "debounce";
import { instance, endpoints } from "../axios/axios.config";
import { AuthContext } from "../authentication/AuthContext";
import { validateData } from "../Utils/validationSchema";
import FormWrapper from "../Components/FormWrapper";
import AppButton from "../Components/AppButton";
import DOMPurify from "dompurify";
import PageLoader from "../Components/PageLoader";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const CreatePassword = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const { setBanner } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(true);
  const [displayForm, setDisplayForm] = useState(false);
  const [errors, setErrors] = useState({});
  const token = query.get("token");

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
    linkId: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: DOMPurify.sanitize(value),
    });
  };

  const createPasswordSchema = Joi.object({
    linkId: Joi.number().max(999999).required(),
    password: Joi.string().min(8).max(72).required(),
    confirmPassword: Joi.string()
      .valid(Joi.ref("password"))
      .min(8)
      .max(72)
      .required()
      .messages({
        "any.only": "must be matching passwords",
      }),
  }).messages({
    "any.any": "Invalid input",
    "string.min": "must be at least {{#limit}} characters long",
    "string.empty": "Input field required",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const validation = validateData(formData, createPasswordSchema);
    if (validation) {
      const newErrors = {};
      validation.details.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }
    setErrors({});
    try {
      await instance.post(endpoints.createPassword, formData);
      setBanner({ type: "success", message: "Password updated successfully" });
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (err) {
      setBanner({
        message: err,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedHandleSubmit = debounce((e) => handleSubmit(e), 300);

  useEffect(() => {
    setIsVerifying(true);
    const { error } = Joi.string().max(2000).required().validate(token);
    if (error) {
      setBanner({ message: "Invalid request. Please try again" });
      setTimeout(() => {
        navigate("/forgot-password");
      }, 2000);
      return;
    }

    const validateLink = async (token) => {
      try {
        const response = await instance.post(endpoints.validatePasswordLink, {
          token,
        });
        if (response?.data?.linkId) {
          setDisplayForm(true);
          return setFormData((prevFormData) => ({
            ...prevFormData,
            linkId: parseInt(response.data.linkId),
          }));
        }
        setDisplayForm(true);
      } catch (error) {
        setBanner({ message: "Invalid request. Please try again" });
        setTimeout(() => {
          navigate("/forgot-password");
        }, 3000);
      } finally {
        setIsVerifying(false);
      }
    };

    validateLink(token);
  }, [token, setBanner, navigate]);

  return (
    <FormWrapper formTitle="Create Your Password">
      <div className="mt-7 w-full lg:max-w-md">
        {!isVerifying ? (
          displayForm && (
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={(e) => {
                e.preventDefault();
                debouncedHandleSubmit(e);
              }}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    value={formData.password}
                    onChange={handleInputChange}
                    autoComplete="false"
                    className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="text-red mt-1">{errors?.password}</p>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirm Password
                </label>
                <div className="mt-2">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    required
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="text-red mt-1 h-4">{errors?.confirmPassword}</p>
              </div>
              <div>
                <AppButton text="Submit" isLoading={isLoading} />
              </div>
            </form>
          )
        ) : (
          <PageLoader />
        )}
      </div>
    </FormWrapper>
  );
};

export default CreatePassword;
