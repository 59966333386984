import contents from "../assets/content.json";
import AppModal from "./AppModal";
import { useState } from "react";
import FeaturedContent from "./FeaturedContent";

import {
  DocumentTextIcon,
  FilmIcon,
  DocumentIcon,
} from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

const ContentCard = () => {
  const [currentContent, setContent_] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const setContent = (content) => {
    if (!content) return;
    if (content.redirectLink !== "") {
      return window.open(content.redirectLink);
    }
    setContent_(content);
    setOpenModal(true);
  };

  const handleOpenFeatured = () => {
    if (contents.length > 0) {
      setContent(contents[0]);
      return setOpenModal(true);
    }
    setOpenModal(false);
  };

  const iconRendered = (content) => {
    if (!content) return;
    return content.type === "video" ? (
      <FilmIcon className="w-4 h-4 mt-1 " />
    ) : content.type === "article" ? (
      <DocumentTextIcon className="w-4 h-4 mt-1 " />
    ) : (
      <DocumentIcon className="w-4 h-4 mt-1 " />
    );
  };

  const contentHtml =
    contents.length > 0 ? (
      <div className="mt-7 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
        {contents.map((content) => (
          <div
            key={content.contentID}
            className="group relative shadow-lg rounded-xl bg-white hover:scale-105 transition-all"
            onClick={() => setContent(content)}
          >
            <div className="aspect-video rounded-t-xl w-full overflow-hidden  group-hover:opacity-75 lg:h-40">
              <img
                src={content.thumbnail}
                alt={content.title}
                className="h-auto w-full object-contain object-center  lg:w-full "
              />
            </div>

            <div className="px-4 pb-3">
              <div>
                <div className="w-full flex justify-start">
                  <span className="capitalize font-semibold block w-fit text-sm mt-3  border-0  text-secondaryy  px-2 rounded-lg ">
                    {content.type} | {content.date}
                  </span>
                </div>
                <h3 className="text-md text-gray-700 font-bold mt-3 card--title">
                  <Link
                    to={content.s3Link}
                    className="flex items-start justify-start gap-2"
                  >
                    <span aria-hidden="true" className="absolute inset-0 " />
                    <div
                      className="tooltip flex gap-2 items-start justify-start"
                      data-tip={content.title}
                    >
                      {iconRendered(content)}
                      <p className="w-full text-left font-bold line-clamp-2 text-lg">
                        {content.title}
                      </p>
                    </div>
                  </Link>
                </h3>
                <div className="w-full text-left mb-4"></div>

                <p className="w-64 max-w-full line-clamp-2 text-sm text-grey">
                  {content.description}
                </p>
              </div>
            </div>
          </div>
        ))}{" "}
      </div>
    ) : (
      "No Content Available."
    );

  return (
    <>
      {openModal && (
        <AppModal
          onClose={() => setOpenModal(false)}
          content={currentContent}
          openModal={openModal}
        />
      )}

      <div className=" w-full h-full min-h-screen lg:pt-14 md:pt-16 sm:pt-16 pt-16">
        <FeaturedContent openFeatured={handleOpenFeatured} />
        <div className="mx-auto max-w-2xl px-4 py-8   sm:px-6 sm:py-15 lg:max-w-7xl lg:px-8">
          <h3 className="text-3xl font-bold tracking-tight mt-3">Contents</h3>
          {contentHtml}
        </div>
      </div>
    </>
  );
};

export default ContentCard;
