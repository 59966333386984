const AppButton = ({ isLoading, text, type = "submit", isPrimary = true }) => {
  return (
    <button
      type={type}
      disabled={isLoading}
      data-testid="submit-btn"
      className={`flex w-full justify-center rounded-md ${
        isPrimary
          ? "bg-primaryy text-white hover:bg-primaryy"
          : "bg-white text-grey hover:bg-white"
      }  px-3 py-1.5 text-sm font-semibold leading-6  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 `}
    >
      {isLoading ? (
        <img
          className="text-white invert h-5"
          src="https://assets.coredataresearch.com/coredataexchange.com.au/images/library/spinner.svg"
          alt="spinner"
        />
      ) : (
        text
      )}
    </button>
  );
};

export default AppButton;
