import { useState, useContext } from "react";
import { instance, endpoints } from "../axios/axios.config";
import { Link, useNavigate } from "react-router-dom";
import Joi from "joi";
import { tlds } from "@hapi/tlds";
import DOMPurify from "dompurify";
import debounce from "debounce";
import { AuthContext } from "../authentication/AuthContext";
import { validateData } from "../Utils/validationSchema";
import AppButton from "../Components/AppButton";
import FormWrapper from "../Components/FormWrapper";

const SignUp = () => {
  const navigate = useNavigate();
  const { setBanner } = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    principalOrAdviser: "",
    advisersNo: "0",
    isSelfLicensed: 0,
    isSubscribe: "",
    password: "",
    confirmPassword: "",
  });
  const australianPhoneNumberRegex = /^(1[4698]|2|3|4|5[50]|7|8)\d{8}$/;
  const allowedLettersHypens =
    /^[a-zA-ZñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙâêîôûÂÊÎÔÛäëïöüÄËÏÖÜãõÃÕçÇ' -]+$/;
  const signupSchema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: tlds } })
      .max(255)
      .required()
      .messages({
        "string.email": "Must be a valid email address",
      }),
    firstName: Joi.string()
      .pattern(allowedLettersHypens)
      .min(2)
      .max(50)
      .required(),
    lastName: Joi.string()
      .pattern(allowedLettersHypens)
      .min(2)
      .max(50)
      .required(),
    phone: Joi.string().pattern(australianPhoneNumberRegex).max(9).messages({
      "string.pattern.base": "Invalid australian phone number",
    }),
    principalOrAdviser: Joi.string()
      .valid("practice principal", "adviser")
      .required(),
    advisersNo: Joi.string()
      .valid("0", "1", "2", "3", "4", "5", "more than 5")
      .required(),
    isSelfLicensed: Joi.number().valid(0, 1).required(),
    isSubscribe: Joi.number().valid(0, 1, 2).required().messages({
      "number.base": "Must not be empty",
      "any.required": "Must not be empty",
      "number.invalid": "Must be a valid option",
    }),
    password: Joi.string().min(8).max(72).required().messages({
      "string.min": "Must be at least {{#limit}} characters long",
    }),
    confirmPassword: Joi.string()
      .valid(Joi.ref("password"))
      .min(8)
      .max(72)
      .required()
      .messages({
        "any.only": "Must be matching passwords",
        "string.min": " Must be at least {{#limit}} characters long",
      }),
  }).messages({
    "any.any": "Invalid input",
    "string.empty": "Input field required",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!name) return;
    if (name === "phone") {
      const numericValue = value.replace(/\D/g, "").replace(/^0+/, "");
      setFormData({
        ...formData,
        [name]: DOMPurify.sanitize(numericValue),
      });
      return;
    }
    if (name === "isSubscribe") {
      setFormData({
        ...formData,
        [name]: Number(DOMPurify.sanitize(value)),
      });
      return;
    }

    setFormData({
      ...formData,
      [name]: DOMPurify.sanitize(value),
    });
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (!name) return;
    setFormData({
      ...formData,
      [name]: checked ? 1 : 0,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const validation = validateData(formData, signupSchema);
    if (validation) {
      const newErrors = {};
      validation.details.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }
    setErrors();
    try {
      const response = await instance.post(endpoints.register, formData);
      const data = response?.data;
      if (!data)
        return setBanner({
          message: "Unpexpected error occured. Please try again.",
        });
      const { error: verificationIdError } = Joi.number()
        .max(999999)
        .required()
        .validate(data?.verificationId);
      if (verificationIdError) {
        return setBanner({
          message: "Unpexpected error occured. Please try again.",
        });
      }
      localStorage.setItem("_ver_ID", data.verificationId);
      navigate("/verify");
    } catch (err) {
      if (typeof err === "object" && !err.message) {
        setBanner({
          message: "Invalid Request",
        });
        return;
      }
      if (err.message === "Invalid Account") {
        localStorage.setItem("_ver_ID", err?.verificationId);
      }
      setBanner({
        message: err?.message,
        isVerify: err.message === "Invalid Account",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedHandleSubmit = debounce((e) => handleSubmit(e), 100);

  return (
    <FormWrapper formTitle="Create your account" prevLink="/">
      <div className="mt-7 w-full   ">
        <form
          className="space-y-1 sign-up-form"
          action="#"
          method="POST"
          data-testid="signup-form"
          onSubmit={(e) => {
            e.preventDefault();
            debouncedHandleSubmit(e);
          }}
        >
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                data-testid="signup-email"
                autoComplete="email"
                maxLength={255}
                required
                value={formData.email}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 input-sm"
              />
            </div>
            <p className="text-error mt-0 text-sm h-2">{errors?.email}</p>
          </div>
          <div className="flex items-center  gap-4 lg:flex-row md:flex-row sm:flex-col flex-col">
            <div className="lg:w-1/2 md:w-1/2 sm:w-full w-full">
              <label
                htmlFor="first_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                First Name
              </label>
              <div className="mt-2">
                <input
                  id="first_name"
                  name="firstName"
                  data-testid="signup-firstname"
                  type="text"
                  maxLength={50}
                  required
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 input-sm"
                />
              </div>
              <p className="text-error mt-0 text-sm h-2">{errors?.firstName}</p>
            </div>
            <div className="lg:w-1/2 md:w-1/2 sm:w-full w-full">
              <label
                htmlFor="last_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Last Name
              </label>
              <div className="mt-2">
                <input
                  id="last_name"
                  name="lastName"
                  data-testid="signup-lastname"
                  type="text"
                  maxLength={50}
                  required
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 input-sm"
                />
              </div>
              <p className="text-error mt-0 text-sm h-2">{errors?.lastName}</p>
            </div>
          </div>
          <div className="flex items-center  gap-4 justify-between lg:flex-row md:flex-row sm:flex-col flex-col">
            <div className="lg:w-1/2 md:w-1/2 sm:w-full w-full">
              <label
                htmlFor="last_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Mobile Number
              </label>
              <label className=" mt-2 input input-bordered input-sm px-0 flex items-center gap-1 w-full sm:w-full border-0 justify-start sm:justify-start md:justify-end lg:justify-end">
                <label className=" w-full input-bordered input-sm flex items-center gap-2  rounded-md shadow-sm ring-1 ring-inset border-0 focus:ring-inset focus:ring-2">
                  +61
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    data-testid="signup-phone"
                    required
                    value={formData.phone}
                    maxLength={9}
                    onChange={handleInputChange}
                    className="block w-full  placeholder:text-gray-400 ring-0 focus:ring-0 border-0 outline-0 sm:text-sm sm:leading-6 text-sm"
                  />
                </label>
              </label>
              <p className="text-error mt-0 text-sm h-2">{errors?.phone}</p>
            </div>
            <div className="lg:w-1/2 md:w-1/2 sm:w-full w-full">
              <label
                htmlFor="company"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <p className="text-sm">
                  {" "}
                  How many advisers are in your practice?
                </p>
              </label>
              <div className="mt-2">
                <select
                  name="advisersNo"
                  id="advisersNo"
                  data-testid="signup-advisersno"
                  value={formData.advisersNo}
                  required
                  onChange={handleInputChange}
                  className="select select-bordered select-sm block w-full py-0 rounded-md border-0 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                >
                  <option value="0">N/A</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="more than 5">more than 5</option>
                </select>
              </div>
              <p className="text-error mt-0 text-sm h-2">
                {errors?.advisersNo}
              </p>
            </div>
          </div>

          <div className="flex items-center  gap-4  lg:flex-row md:flex-row sm:flex-col flex-col">
            <div className="lg:w-1/2 md:w-1/2 sm:w-full w-full">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  data-testid="signup-password"
                  required
                  value={formData.password}
                  onChange={handleInputChange}
                  autoComplete="false"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 input-sm"
                />
              </div>
              <p className="text-error mt-0 text-sm h-2">{errors?.password}</p>
            </div>
            <div className="lg:w-1/2 sm:w-full w-full">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Confirm Password
              </label>
              <div className="mt-2">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  data-testid="signup-confirm-password"
                  required
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 input-sm"
                />
              </div>
              <p className="text-error mt-0 text-sm h-2">
                {errors?.confirmPassword}
              </p>
            </div>
          </div>

          <div>
            <div className="mt-2 flex gap-3 pl-1">
              <div className="w-full">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Your role in your practice:
                </label>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-2 flex gap-3 pl-1">
              <div className="w-fit">
                <label className="label px-0 cursor-pointer flex gap-3 justify-start">
                  <input
                    type="radio"
                    name="principalOrAdviser"
                    value="practice principal"
                    data-testid="signup-radio-principal-adviser"
                    className="radio checked:bg-primaryy radio-sm "
                    checked={
                      formData.principalOrAdviser === "practice principal"
                    }
                    onChange={handleInputChange}
                  />
                  <span className="label-text">Practice Principal</span>
                </label>
                <p className="text-error mt-0 text-sm h-2">
                  {errors?.principalOrAdviser}
                </p>
              </div>
              <div className="w-fit">
                <label className="label cursor-pointer flex gap-3 justify-start">
                  <input
                    type="radio"
                    name="principalOrAdviser"
                    value="adviser"
                    data-testid="signup-radio-principal-adviser1"
                    className="radio checked:bg-primaryy radio-sm"
                    checked={formData.principalOrAdviser === "adviser"}
                    onChange={handleInputChange}
                  />
                  <span className="label-text">Adviser</span>
                </label>
              </div>
            </div>
          </div>

          <div>
            <div className="form-control items-start">
              <label className="cursor-pointer label">
                <input
                  id="isSelfLicensed"
                  type="checkbox"
                  name="isSelfLicensed"
                  data-testid="signup-is-licensed"
                  className="checkbox checkbox-info rounded-md checkbox-sm"
                  value={formData.isSelfLicensed}
                  onChange={handleCheckboxChange}
                />
                <span className="label-text ml-3">I am self licensed</span>
              </label>
            </div>
          </div>

          <div>
            <div className="form-control items-start">
              <label
                htmlFor="email"
                className="block text-sm font-bold leading-6 text-gray-900"
              >
                Subscribe and receive exclusive content from CoreData Exchange.
              </label>
              <div className="mt-2 flex flex-col gap-0 pl-1">
                <div className="w-fit">
                  <label className="label  cursor-pointer flex gap-3 justify-start">
                    <input
                      type="radio"
                      name="isSubscribe"
                      value={1}
                      data-testid="signup-radio-principal-adviser"
                      className="radio checked:bg-primaryy radio-sm "
                      checked={formData.isSubscribe === 1}
                      onChange={handleInputChange}
                    />
                    <span className="label-text">Monthly</span>
                  </label>
                </div>
                <div className="w-fit">
                  <label className="label cursor-pointer flex gap-3 justify-start">
                    <input
                      type="radio"
                      name="isSubscribe"
                      value={2}
                      data-testid="signup-radio-principal-adviser1"
                      className="radio checked:bg-primaryy radio-sm"
                      checked={formData.isSubscribe === 2}
                      onChange={handleInputChange}
                    />
                    <span className="label-text">Bimonthly </span>
                  </label>
                </div>
                <div className="w-fit">
                  <label className="label cursor-pointer flex gap-3 justify-start">
                    <input
                      type="radio"
                      name="isSubscribe"
                      value={0}
                      data-testid="signup-radio-principal-adviser1"
                      className="radio checked:bg-primaryy radio-sm"
                      checked={formData.isSubscribe === 0}
                      onChange={handleInputChange}
                    />
                    <span className="label-text">
                      Dont want to receive emails
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <p className="text-error mt-0 text-sm h-2">{errors?.isSubscribe}</p>
          </div>

          <div className="pt-3">
            <AppButton text="Submit" isLoading={isLoading} />
          </div>
        </form>

        <p className="mt-7 text-center text-sm text-gray-500">
          Already have an account?{" "}
          <Link
            to={"/login"}
            className="font-semibold leading-6 text-secondaryy hover:text-indigo-500"
          >
            Login here.
          </Link>
        </p>
      </div>
    </FormWrapper>
  );
};

export default SignUp;
