import PopupContentBody from "./PopupContentBody";
const AppModal = ({ content, onClose, openModal }) => {
  return (
    <div>
      <dialog id="my_modal_3" className="modal" open={openModal}>
        <div className="modal-box bg-white text-black">
          <form method="dialog ">
            <div
              className="btn btn-sm btn-circle btn-ghost  absolute right-2 top-2 z-30"
              onClick={() => onClose()}
            >
              ✕
            </div>
            {content && <PopupContentBody content={content} />}
          </form>
        </div>
      </dialog>
    </div>
  );
};

export default AppModal;
