import NotificationBanner from "./NotificationBanner";
import { AuthContext } from "../authentication/AuthContext";
import { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import PrevIcon from "../Components/PrevIcon";

const FormWrapper = ({
  children,
  prevLink,
  formTitle,
  formCaption,
  isInnerPage,
}) => {
  const { banner, setBanner } = useContext(AuthContext);
  const notificationTimeout = useRef(null);

  useEffect(() => {
    if (notificationTimeout.current) {
      clearTimeout(notificationTimeout.current);
    }
    if (!banner?.message) return;
    notificationTimeout.current = setTimeout(() => {
      setBanner({});
    }, 5000);
    return () => {
      if (notificationTimeout.current) {
        setBanner();
        clearTimeout(notificationTimeout.current);
      }
    };
  }, [banner, setBanner]);

  return (
    <div className="flex items-center justify-center w-full max-w-screen h-full min-h-screen lg:py-5 md:py-5 sm:py-0 py-0">
      <div
        className={
          isInnerPage
            ? "flex flex-col justify-center items-center px-6 py-6 lg:px-8 w-fit h-fit shadow-md rounded-md bg-white relative text-left settings-container mt-32 mb-20"
            : "off-root-container shadow-md rounded-md bg-white relative "
        }
      >
        {prevLink && (
          <Link
            to={prevLink}
            className="btn btn-sm btn-circle btn-ghost absolute left-3 top-3 z-10"
          >
            <PrevIcon />
          </Link>
        )}
        {!isInnerPage && (
          <div className="sm:mx-auto sm:w-full sm:max-w-sm text-center">
            <Link to={"/"}>
              <img
                className="mx-auto h-20 w-auto"
                src="https://assets.coredataresearch.com/coredataexchange.com/images/cd_exchange_logo.png"
                alt="Your Company"
              />
            </Link>
            <h2
              className="mt-7 mb-3   text-4xl font-bold leading-9 tracking-tight text-gray-900"
              data-testid="form-title"
            >
              {formTitle}
            </h2>
            {formCaption && <span className="text-sm px-6">{formCaption}</span>}
          </div>
        )}

        {children}
      </div>
      {banner?.message && (
        <NotificationBanner
          message={banner.message}
          type={banner.type}
          isVerify={banner.isVerify}
        />
      )}
    </div>
  );
};

export default FormWrapper;
